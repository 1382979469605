"use client";

import SearchModal from "components/Blog/SearchModal";
import Image from "next/image";

type SectionCard = {
  icon: string;
  title: string;
  sectionId: string;
};

export default function EventsNavigation() {
  const sectionCards: SectionCard[] = [
    { icon: "/assets/icons/logic-outline-64.svg", title: "AI", sectionId: "ai-events" },
    {
      icon: "/assets/icons/widget-outline-24.svg",
      title: "Low Code Development",
      sectionId: "low-code-development-events",
    },
    {
      icon: "/assets/icons/users-outline-24.svg",
      title: "Customer Stories",
      sectionId: "customer-success-stories-events",
    },
    { icon: "/assets/icons/appsmith-ai.svg", title: "Appsmith Features", sectionId: "appsmith-features-events" },
  ];

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {" "}
      <div className="flex items-center justify-end pb-xs">
        <SearchModal searchType="all" />
      </div>
      <section className="mx-auto mb-lg rounded-md bg-secondary-light-50 px-md py-xs">
        <div className="mx-auto grid grid-cols-2 gap-sm md:grid-cols-4 xl:grid-cols-4">
          {sectionCards.map((card) => (
            <button
              key={card.sectionId}
              onClick={() => scrollToSection(card.sectionId)}
              className="group flex cursor-pointer flex-col items-center rounded-lg border border-secondary-light-100 bg-primary-neutral-50 p-xs transition-all duration-300 hover:border-primary-light-500 hover:shadow-md"
            >
              <span className="mb-xs text-3xl transition-transform duration-300 group-hover:scale-110">
                <Image src={card.icon} alt={card.title} width={32} height={32} />
              </span>
              <span className="text-center text-2xs font-medium sm:text-xs">{card.title}</span>
            </button>
          ))}
        </div>
      </section>
    </>
  );
}
