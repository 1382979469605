import BasicCard from "components/Cards/BasicCard/BasicCard";
import BlogCard from "components/Cards/BlogCard/BlogCard";
import IntegrationCard from "components/Cards/IntegrationCard/IntegrationCard";
import PillCard from "components/Cards/PillCard/PillCard";
import TemplateCard from "components/Cards/TemplateCard/TemplateCard";
import UseCaseCard from "components/Cards/UseCaseCard/UseCaseCard";
import VideoComponentCard from "components/Cards/VideoComponentCard/VideoComponentCard";
import WidgetCard from "components/Cards/WidgetCard/WidgetCard";
import TeamCardDeck from "components/TeamCardDeck/TeamCardDeck";

import clsx from "clsx";
import richTextParser, { RichText } from "utils/richTextParser";

import CtaLink from "components/CtaLink";
import {
  componentVideoFragment,
  ctaFragment,
  templateBlogPostListingFragment,
  templateCaseStudyListingFragment,
  templateIntegrationListingFragment,
  templateTemplateListingFragment,
  templateUseCaseListingFragment,
  templateWidgetListingFragment,
} from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentCardDeckFragment, ComponentCardFragment, ComponentCardFragmentDoc } from "contentful/gql/graphql";
import typography from "components/typography";
import { cn } from "utils/functions";
import ColorizedText from "components/ColorizedText";

const layouts = {
  "2x1": "sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 sm:space-y-0",
  "3x1": "sm:grid sm:grid-cols-2 sm:gap-8 lg:grid-cols-3 lg:space-y-0",
  "3x2": "sm:grid sm:grid-cols-2 sm:gap-8 lg:grid-cols-3 lg:space-y-0",
  "4x1": "grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16",
} as const;

type layoutProps = keyof typeof layouts;

const CardDeck = ({
  cardCollection,
  layout,
  heading,
  position,
  callToAction: callToActionFragment,
  callToActionPosition = "Above",
  type,
  body,
}: ComponentCardDeckFragment) => {
  const callToAction = getFragmentData(ctaFragment, callToActionFragment);
  const cards = cardCollection?.items;

  const renderCards = (card: ComponentCardFragment) => {
    if (type === "Pill" || type === "Pill (Dark)") {
      return <PillCard key={card?.sys.id} {...card} />;
    }

    if (card?.teamMember) {
      return <TeamCardDeck key={card?.sys.id} {...card} />;
    }

    return <BasicCard key={card?.sys.id} {...card} />;
  };

  return (
    <div
      className={`relative ${
        heading && (position === "Left" || position === "Right") ? "lg:grid lg:grid-cols-3 lg:gap-x-8 2xl:gap-x-12" : ""
      }`}
    >
      {(heading || body) && (
        <div
          className={
            position === "Center"
              ? "mb-xs text-center md:mb-sm xl:mb-md "
              : ` lg:col-span-1 lg:row-start-1 lg:col-start-${position === "Left" ? "1" : "3"} flex`
          }
        >
          <div>
            {heading && (
              <h2 className={cn(typography.heading.sectionHeading, "mb-2xs")}>
                <ColorizedText heading={heading} />
              </h2>
            )}
            {body && (
              <div className={"text-primary-dark-500 dark:text-primary-dark-100"}>
                {richTextParser(body as RichText)}
              </div>
            )}
            {callToAction && (!callToActionPosition || callToActionPosition === "Above") ? (
              <CtaLink {...callToAction} />
            ) : null}
          </div>
        </div>
      )}
      <div
        className={clsx(
          "gap-sm xl:gap-md",
          position === "Center"
            ? ""
            : `lg:col-span-2 lg:row-start-1 lg:mt-0 lg:col-start-${position === "Left" ? "2" : "1"}`,
        )}
      >
        <dl
          className={`flex flex-col gap-sm sm:grid xl:gap-md ${clsx(
            layouts[(layout || "3x1") as layoutProps],
            type === "Pill (Dark)" || type === "Pill" ? "sm:gap-x-6 sm:gap-y-6" : "",
          )}`}
        >
          {cards?.map((card) => {
            switch (card?.__typename) {
              case "ComponentCard":
                return renderCards(getFragmentData(ComponentCardFragmentDoc, card));
              case "TemplateBlogPost":
                const blog = getFragmentData(templateBlogPostListingFragment, card);
                return <BlogCard blog={blog} key={blog?.sys.id} />;
              case "TemplateCaseStudy":
                const caseStudy = getFragmentData(templateCaseStudyListingFragment, card);
                return <BlogCard caseStudy={caseStudy} key={caseStudy?.sys.id} />;
              case "TemplateTemplate":
                const template = getFragmentData(templateTemplateListingFragment, card);
                return <TemplateCard template={template} key={template?.sys.id} />;
              case "TemplateIntegration":
                const integration = getFragmentData(templateIntegrationListingFragment, card);
                return <IntegrationCard integration={integration} key={integration?.sys.id} />;
              case "TemplateWidget":
                const widget = getFragmentData(templateWidgetListingFragment, card);
                return <WidgetCard widget={widget} key={widget?.sys.id} />;
              case "TemplateUseCase":
                const useCase = getFragmentData(templateUseCaseListingFragment, card);
                return <UseCaseCard useCase={useCase} key={useCase?.sys.id} />;
              case "ComponentVideo":
                const video = getFragmentData(componentVideoFragment, card);
                return <VideoComponentCard video={video} key={video?.sys.id} />;
              default:
                return null;
            }
          })}
        </dl>
      </div>
      {callToAction && callToActionPosition === "Below" ? (
        <div className="mt-lg text-center">
          <CtaLink {...callToAction} />
        </div>
      ) : null}
    </div>
  );
};

export default CardDeck;
