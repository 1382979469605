import { cva, VariantProps } from "class-variance-authority";
import { clsx } from "clsx";
import CtaLink from "components/CtaLink";
import Icon from "components/Icon";
import typography from "components/typography";
import { assetFragment, ctaFragment } from "contentful/fragments";
import { getFragmentData } from "contentful/gql";
import { ComponentCardFragment } from "contentful/gql/graphql";
import Image from "next/image";
import { Suspense } from "react";
import { cn } from "utils/functions";
import richTextParser, { RichText } from "utils/richTextParser";

const BasicCard = ({
  heading,
  headingType,
  callToAction: callToActionFragment,
  textAlign,
  layout,
  contained,
  body,
  image: imageFragment,
  icon: iconFragment,
  sys,
  spacing,
  iconStyle,
  isBlurred,
  eyebrow,
}: ComponentCardFragment) => {
  const callToAction = getFragmentData(ctaFragment, callToActionFragment);
  const icon = getFragmentData(assetFragment, iconFragment);
  const image = getFragmentData(assetFragment, imageFragment);
  const iconClasses = cva(["flex h-lg w-lg rounded-full p-2xs"], {
    variants: {
      style: {
        subdued: "bg-primary-light-100/20 text-primary-light-500 dark:bg-primary-dark-500 dark:text-primary-light-50",
        prominent: "bg-icon-gradient text-primary-light-50",
      },
    },
  });

  const cardClasses = cva(
    [
      "overflow-hidden flex flex-col relative",
      isBlurred && "rounded-xl p-xs border border-primary-light-100/20 dark:border-primary-light-100/5",
    ],
    {
      variants: {
        contained: {
          true: "rounded-2xl bg-secondary-light-100/60 dark:bg-primary-dark-700/30 dark:border dark:border-primary-dark-500/50",
        },
        textAlign: {
          left: "text-left items-start",
          center: "text-center items-center",
          right: "text-right items-end",
        },
      },
    },
  );

  return (
    <div
      key={sys.id}
      className={cardClasses({
        contained: contained,
        textAlign: textAlign?.toLowerCase() as VariantProps<typeof cardClasses>["textAlign"],
      })}
    >
      {isBlurred && (
        <div
          className="after:from-white/10 after:via-transparent after:to-transparent dark:after:from-white/5 
           absolute inset-0
          z-50 flex
          scale-125
          items-end
          justify-end
          rounded-md bg-gradient-to-b from-secondary-light-50/50 to-secondary-light-50/20 px-[7%]
          py-[2%]
          backdrop-blur-[8px]
          transition-colors
          duration-500
          ease-in-out after:absolute after:inset-0 after:bg-gradient-to-tr
          hover:from-primary-light-50/30 hover:to-primary-light-50/20 dark:from-primary-dark-900/20
          dark:to-primary-dark-900/20  dark:px-[8%]
          dark:py-[12%] dark:hover:from-primary-dark-900/30 dark:hover:to-primary-dark-900/35"
        >
          <Image
            src="/assets/images/logos/appsmith_logomark.png"
            alt="Appsmith Logo"
            width={80}
            height={80}
            className="scale-50 opacity-10 dark:opacity-5"
          />
        </div>
      )}
      {icon?.url?.endsWith(".svg") && (
        <div
          className={cn(
            iconClasses({
              style: iconStyle === "Subdued" ? "subdued" : iconStyle === "Prominent" ? "prominent" : "subdued",
            }),
            contained ? "mt-xs lg:mt-sm 2xl:mt-md" : "",
          )}
        >
          <Icon url={icon.url} />
        </div>
      )}
      {image?.url && (
        <Image
          src={image?.url || ""}
          alt={image?.title || "Image"}
          width={image?.width || 832}
          height={image?.height || 520}
          sizes="50vw"
          quality={90}
          priority={false}
          loading="lazy"
          className="aspect-video object-cover"
        />
      )}
      <div
        className={cn(
          contained ? (spacing === "More whitespace" ? "p-md xl:p-lg" : "p-xs xl:p-sm") : "",
          !!image?.url || !!icon?.url ? "pt-2xs xl:pt-sm" : "",
        )}
      >
        <dt>
          {eyebrow && (
            <p className={clsx(typography.label, "mb-2xs text-primary-light-500 dark:text-primary-light-300")}>
              {eyebrow}
            </p>
          )}
          {heading && headingType === "H2" ? (
            <h2
              className={clsx(
                typography.heading.secondaryComponentHeading,
                spacing === "More whitespace" ? "mb-md" : "mb-2xs",
                "text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h2>
          ) : heading && headingType === "H3" ? (
            <h2
              className={clsx(
                typography.heading.secondaryComponentHeading,
                spacing === "More whitespace" ? "mb-md" : "mb-2xs",
                "text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h2>
          ) : headingType === "H4" ? (
            <h3
              className={clsx(
                typography.heading.cardHeading,
                spacing === "More whitespace" ? "mb-md" : "mb-2xs",
                "text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h3>
          ) : (
            <h3
              className={clsx(
                typography.heading.cardHeading,
                spacing === "More whitespace" ? "mb-md" : "mb-2xs",
                "text-primary-dark-900 dark:text-primary-neutral-100",
              )}
            >
              {heading}
            </h3>
          )}
        </dt>

        <Suspense fallback={""}>
          {body && (
            <dd
              className={cn(
                typography.body.cardBody,
                "text-balance text-primary-dark-700 dark:text-primary-neutral-200",
              )}
            >
              {body &&
                richTextParser(body as RichText, headingType === "H4" ? "xs" : headingType === "H3" ? "sm" : "md")}
            </dd>
          )}
        </Suspense>
        {callToAction && (
          <div className="mt-sm">
            <CtaLink {...callToAction} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicCard;
