"use client";

import clsx from "clsx";
import CtaLink from "components/CtaLink";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { AssetFragment } from "contentful/gql/graphql";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import ArrowTopRight_12px from "icons/ArrowTopRight_12px";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { cn } from "utils/functions";

export interface HomepageShowcaseConfigType {
  viewMoreLink: ViewMoreLink;
  autoplay: boolean;
  useCases: UseCase[];
}

interface ViewMoreLink {
  label: string;
  href: string;
  hasIcon: boolean;
}

interface UseCase {
  label: string;
  imageAssetReferenceId: string;
  videoAssetReferenceId: string;
  useCasePosition: string;
  useCaseDescription: string;
  useCaseButtonLink: string;
  useCaseButtonLabel: string;
  autoplayDuration: number; // Duration in seconds
}

export default function HomepageShowcaseV1({
  json,
  assets,
}: {
  json: HomepageShowcaseConfigType;
  assets: (AssetFragment | undefined)[] | undefined;
}) {
  const { viewMoreLink, autoplay, useCases } = json;
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Calculate autoplay speed based on the useCase's autoplayDuration
  const getAutoplaySpeed = useCallback(() => {
    const currentUseCase = useCases[selectedIndex];
    return currentUseCase.autoplayDuration;
  }, [useCases, selectedIndex]);

  const [emblaMainRef, emblaMainApi] = useEmblaCarousel(
    { align: "start" },
    autoplay ? [Autoplay({ delay: getAutoplaySpeed(), stopOnInteraction: true })] : [],
  );
  const [emblaHeadingRef, emblaHeadingApi] = useEmblaCarousel({
    dragFree: true,
  });

  const onHeadingClick = useCallback(
    (index: number) => {
      if (!emblaMainApi || !emblaHeadingApi) return;
      emblaMainApi.scrollTo(index);
      emblaMainApi.plugins().autoplay?.stop();
    },
    [emblaMainApi, emblaHeadingApi],
  );

  const onSelect = useCallback(() => {
    if (!emblaMainApi || !emblaHeadingApi) return;
    setSelectedIndex(emblaMainApi.selectedScrollSnap());
    emblaHeadingApi.scrollTo(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, emblaHeadingApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on("select", onSelect);
    emblaMainApi.on("reInit", onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <div className="rounded-md bg-primary-light-100/20">
      <div className="mb-sm grid hidden justify-items-center gap-sm p-3xs lg:grid">
        <div className="relative col-span-8 grid auto-cols-fr grid-flow-col justify-items-center gap-sm text-center">
          {useCases.map((useCase, index) => {
            return (
              <div
                className={clsx(
                  typography.label,
                  "relative z-10 cursor-pointer rounded-md py-xs font-display text-sm transition-colors duration-300 xl:text-sm",
                  selectedIndex === index && "!font-bold delay-150",
                  selectedIndex !== index && "",
                )}
                aria-label={useCase.label}
                key={useCase.label + "-tab-" + index}
                onClick={() => onHeadingClick(index)}
              >
                {useCase.label}
              </div>
            );
          })}
          <div
            className="absolute -top-1 left-0 h-full border-b-4 border-primary-light-500 transition-transform duration-300 ease-in-out will-change-transform"
            style={{
              width: `calc((100% + 20px) / ${useCases.length})`,
              transform: `translateX(calc(${selectedIndex} * 100% - (${10}px)))`,
            }}
          ></div>
        </div>
        {/* <div className="relative col-span-2 flex items-center justify-center text-center">
          <div className="absolute left-0 top-1/2 h-4/5 -translate-x-[10px] -translate-y-1/2 border-r border-r-primary-dark-900/30"></div>
          <CtaLink
            label={viewMoreLink.label}
            link={viewMoreLink.href}
            variant="Link"
            size="Small"
            endIcon={viewMoreLink.hasIcon ? "ArrowTopRight" : undefined}
          />
        </div> */}
      </div>
      <div className="mb-sm rounded bg-primary-light-100/10 p-3xs lg:hidden">
        <div className="overflow-hidden" ref={emblaHeadingRef}>
          <div className="flex space-x-sm">
            {useCases.map((useCase, index) => (
              <div className="relative flex-[0_0_auto]" key={useCase.label}>
                <button
                  className={clsx(
                    typography.label,
                    "px-sm py-xs font-light transition-colors",
                    selectedIndex === index ? "border-b-4 border-primary-light-500 !font-bold" : "",
                  )}
                  onClick={() => onHeadingClick(index)}
                >
                  {useCase.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mx-auto overflow-hidden lg:px-lg" ref={emblaMainRef}>
        <div className="flex space-x-sm">
          {useCases.map((useCase, index) => {
            const asset = assets?.find((asset) => asset?.sys.id === useCase.imageAssetReferenceId);
            const assetVideo = assets?.find((asset) => asset?.sys.id === useCase.videoAssetReferenceId);
            return (
              <div
                key={useCase.label + "-content-" + index}
                className={cn(
                  "relative flex h-auto w-full min-w-0 flex-[0_0_100%] object-contain object-top transition-opacity duration-1000 max-lg:flex-col-reverse",
                  selectedIndex === index && "opacity-100",
                  selectedIndex !== index && "opacity-0",
                  useCase.useCasePosition === "left" && "flex-row",
                  useCase.useCasePosition === "right" && "flex-row-reverse",
                )}
              >
                <div className="flex flex-[0_0_30%] flex-col justify-center p-xs max-lg:mb-sm max-lg:items-center max-lg:p-3xs lg:pr-lg_2">
                  <div className="font-display text-xl font-bold max-lg:flex-row-reverse max-lg:text-xs">
                    {useCase.useCaseDescription}
                  </div>
                  {useCase.useCaseButtonLabel && useCase.useCaseButtonLink && (
                    <div className="md:mt-x mt-xs flex">
                      <a
                        className="group relative inline-flex items-center whitespace-nowrap rounded px-sm py-2xs font-display text-xs font-bold text-primary-neutral-50 transition hover:text-primary-light-500 dark:hover:text-primary-neutral-50 sm:px-sm sm:py-xs lg:text-sm xl:px-md xl:py-xs 2xl:px-lg"
                        target="_blank"
                        href={useCase.useCaseButtonLink}
                      >
                        <span className="relative z-10 flex items-center justify-center whitespace-nowrap leading-none">
                          {useCase.useCaseButtonLabel}
                        </span>
                        <span className="absolute left-0 top-0 h-full w-full rounded border-[1px] border-primary-light-500 bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 opacity-0 transition-opacity duration-300 group-hover:opacity-100 dark:from-primary-light-500/50 dark:to-primary-light-650/50"></span>
                        <span className="absolute left-0 top-0 h-full w-full rounded bg-gradient-to-br from-primary-light-500 to-primary-light-650  transition-opacity duration-300 group-hover:opacity-0"></span>
                      </a>
                    </div>
                  )}
                </div>
                {assetVideo?.url ? (
                  <video
                    src={assetVideo?.url + "?fm=mp4"}
                    // className="mx-auto my-sm h-auto w-full max-w-screen-lg overflow-hidden rounded-lg"
                    className={cn(
                      "relative mb-xs h-auto w-full min-w-0 flex-[0_0_70%] object-contain object-top transition-opacity duration-1000 max-lg:flex-none",
                      selectedIndex === index && "opacity-100",
                      selectedIndex !== index && "opacity-0",
                    )}
                    width={asset?.width || 0}
                    height={asset?.height || 0}
                    muted
                    // autoPlay={selectedIndex === index}
                    autoPlay
                    loop={selectedIndex === index}
                    playsInline={selectedIndex === index}
                  >
                    <source src={asset?.url + "?fm=mp4"} type="video/mp4" />
                  </video>
                ) : (
                  <Image
                    priority={index === 0}
                    className={cn(
                      "relative h-auto w-full min-w-0 flex-[0_0_70%] object-contain object-top transition-opacity duration-1000",
                      selectedIndex === index && "opacity-100",
                      selectedIndex !== index && "opacity-0",
                    )}
                    src={asset?.url || ""}
                    alt={asset?.title || "Showcase image"}
                    width={asset?.width || 0}
                    height={asset?.height || 0}
                    sizes="
                    (min-width: 1280px) 1024px, 768px
                    "
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      {/* <Hyperlink
        className={clsx(
          typography.label,
          "mt-sm flex h-full items-center justify-center gap-[5px] underline transition-colors hover:text-primary-dark-500 lg:hidden",
        )}
        href={viewMoreLink.href}
      >
        {viewMoreLink.label}
        <span className="h-[12px] w-[12px]">
          <ArrowTopRight_12px />
        </span>
      </Hyperlink> */}
    </div>
  );
}
