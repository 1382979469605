"use client";

import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import codeLottieJson from "lottie/code.json";

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
ScrollTrigger.defaults({ markers: false });

interface ScrollPathProps {
  children?: React.ReactNode;
}

export const ScrollPath: React.FC<ScrollPathProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const lottieRef = useRef<HTMLDivElement>(null);
  const lottiePlayerRef = useRef<any>(null);
  const circleRef = useRef<SVGCircleElement>(null);

  useEffect(() => {
    if (!containerRef.current || !lottieRef.current || !lottiePlayerRef.current || !circleRef.current) return;

    // Get the actual path length
    const path = document.querySelector("#progressPath") as SVGPathElement;
    const pathLength = path?.getTotalLength() || 1000;

    // Set initial state of the progress path
    gsap.set("#progressPath", {
      strokeDasharray: pathLength,
      strokeDashoffset: pathLength,
    });

    // Set initial state of the circle
    gsap.set(circleRef.current, {
      motionPath: {
        path: "#motionPath",
        align: "#motionPath",
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
    });

    // Animate the path progress
    gsap.to("#progressPath", {
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top 90%",
        end: "+=400%",
        scrub: 0.5,
      },
      strokeDashoffset: 0,
      ease: "none",
    });

    const totalFrames = lottiePlayerRef.current.totalFrames;
    const contentSections = gsap.utils.toArray<HTMLElement>(".content-section");
    const numSections = contentSections.length;

    // Animate circle along path
    gsap.to(circleRef.current, {
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top 90%",
        end: "+=400%",
        scrub: 0.5,
      },
      motionPath: {
        path: "#motionPath",
        align: "#motionPath",
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
      ease: "none",
    });

    // Main scroll trigger for pinning and Lottie animation
    const mainTrigger = ScrollTrigger.create({
      trigger: containerRef.current,
      start: "top top",
      end: "+=300%",
      pin: true,
      pinSpacing: true,
      scrub: 0.5,
      markers: false,
      onUpdate: (self) => {
        const frame = Math.floor(self.progress * totalFrames);
        lottiePlayerRef.current.goToAndStop(frame, true);
      },
    });

    // Set initial states for all sections
    contentSections.forEach((section) => {
      gsap.set(section, {
        opacity: 0,
        y: 100,
        position: "absolute",
        top: "10%",
        transform: "translateY(-200%)",
        width: "100%",
      });
    });

    // Create animations for each section
    contentSections.forEach((section, index) => {
      const sectionLength = 1 / numSections;
      const sectionStart = index * sectionLength;

      ScrollTrigger.create({
        trigger: containerRef.current,
        start: "top top",
        end: "+=300%",
        scrub: 1,
        markers: false,
        onUpdate: (self) => {
          const progress = (self.progress - sectionStart) / sectionLength;

          if (progress >= 0 && progress <= 1) {
            // Fade in and move up
            if (progress <= 0.5) {
              gsap.to(section, {
                opacity: progress * 2,
                yPercent: -50 + (100 - progress * 200), // Start at bottom (50%), move to center (0%)
                duration: 0,
              });
            }
            // Fade out and continue moving up
            else {
              gsap.to(section, {
                opacity: 2 - progress * 2,
                yPercent: -50 - (progress - 0.5) * 200, // Continue from center (-50%) to top (-150%)
                duration: 0,
              });
            }
          } else {
            // Keep sections hidden outside their range
            gsap.set(section, {
              opacity: 0,
              yPercent: progress < 0 ? 50 : -150, // Below viewport or above viewport
            });
          }
        },
      });
    });

    lottiePlayerRef.current.pause();

    return () => {
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  return (
    <div className="">
      <div ref={containerRef} className="relative min-h-screen">
        <div className="min-h-screen">
          <div className="grid min-h-screen grid-cols-1 gap-20 lg:grid-cols-2">
            {/* Content Column */}
            <div className="flex items-center">
              <div className="relative h-[300px] w-full">
                <div className="content-section space-y-4">
                  <h2 className="text-3_5xl font-bold">Improve team performance and customer satisfaction</h2>
                  <p className="text-gray-600 text-lg">
                    Appsmith provides an end-to-end set of tools to build and deploy GenAI in the enterprise, quickly
                    and securely.
                  </p>
                </div>

                {/* <div className="content-section space-y-4">
                  <h2 className="text-3xl font-bold">Chat-centric interactions with evidence</h2>
                  <p className="text-gray-600 text-lg">
                    Put conversation at the (rightful) center of your applications with a highly-flexible, responsive
                    chat interface. Built-in citations retrieve relevant sources from your data.
                  </p>
                </div>

                <div className="content-section space-y-4">
                  <h2 className="text-3xl font-bold">Intelligent actions</h2>
                  <p className="text-gray-600 text-lg">
                    Bring humans in the loop with interactive workflows for approvals, reviews, and other actions based
                    on AI suggestions. Create and call custom UI components to complete tasks.
                  </p>
                </div>

                <div className="content-section space-y-4">
                  <h2 className="text-3xl font-bold">Embeddable anywhere</h2>
                  <p className="text-gray-600 text-lg">
                    Add AI assistants directly into the platforms your teams use every day. Shared logins (via SSO)
                    create a seamless experience while enforcing access controls.
                  </p>
                </div> */}
              </div>
            </div>

            {/* Timeline Path */}
            <div className="absolute top-0 hidden h-full w-20 md:-left-14 md:block lg:left-1/2 lg:-translate-x-1/2">
              <svg
                className="h-full w-full"
                viewBox="0 0 80 1040"
                preserveAspectRatio="xMidYMid meet"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {/* Vertical line */}
                <defs>
                  <linearGradient id="circleGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stopColor="#501FCD" />
                    <stop offset="100%" stopColor="#7441F7" />
                  </linearGradient>
                  <filter id="glow">
                    <feGaussianBlur stdDeviation="3" result="blur" />
                    <feComposite in="SourceGraphic" in2="blur" operator="over" />
                  </filter>
                </defs>

                {/* Background track (lighter path) */}
                <path id="backgroundPath" d="M40 20L40 1020" stroke="#E5E7EB" strokeWidth="2" strokeLinecap="round" />

                {/* Progress path (black) */}
                <path id="progressPath" d="M40 20L40 1020" stroke="#222222" strokeWidth="2" strokeLinecap="round" />

                {/* Motion path (invisible, used for circle movement) */}
                <path id="motionPath" d="M40 20L40 1020" stroke="transparent" strokeWidth="2" strokeLinecap="round" />

                {/* Progress circle with effects */}
                <g ref={circleRef}>
                  {/* Outer glow */}
                  <circle r="16" fill="rgba(59, 130, 246, 0.2)" className="blur-lg" />
                  {/* Main circle with gradient */}
                  <circle r="12" fill="url(#circleGradient)" className="drop-shadow-lg filter" />
                  {/* Inner ring */}
                  <circle r="10" fill="none" stroke="white" strokeWidth="1.5" className="opacity-50" />
                  {/* Center dot */}
                  <circle r="3" fill="white" className="drop-shadow-md" />
                </g>
              </svg>
            </div>

            {/* Lottie Animation Column */}
            <div ref={lottieRef} className="flex items-center">
              <Lottie ref={lottiePlayerRef} animationData={codeLottieJson} style={{ width: "100%", height: "auto" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollPath;
