"use client";

import { useState } from "react";
import { cn } from "utils/functions";

export default function IframedApp({
  json,
}: {
  json: {
    id: string;
    iframeUrl: string;
    minHeight?: string;
    isResponsive?: boolean;
    isPadded?: boolean;
    bgColor?: string;
  };
}) {
  const { id, iframeUrl, minHeight, isResponsive = true, isPadded = true, bgColor } = json;
  const [isMobile, setIsMobile] = useState(false);

  return (
    <div id={id} style={{ backgroundColor: bgColor }} className={cn("rounded-md shadow-sm", isPadded && "py-5")}>
      {isResponsive && (
        <div className="mb-5 flex items-center justify-end gap-2 pr-5">
          <button
            onClick={() => setIsMobile(false)}
            className={`rounded px-3 py-1 ${!isMobile ? "bg-primary-light-300 text-primary-neutral-100" : "bg-gray-200"}`}
          >
            Desktop
          </button>
          <button
            onClick={() => setIsMobile(true)}
            className={`rounded px-3 py-1 ${isMobile ? "bg-primary-light-300 text-primary-neutral-100" : "bg-gray-200"}`}
          >
            Mobile
          </button>
        </div>
      )}
      <iframe
        className="mx-auto w-full overflow-y-hidden transition-all duration-500"
        src={iframeUrl}
        style={{
          minHeight: minHeight || "400px",
          width: isMobile ? "375px" : "100%",
          margin: isMobile ? "0 auto" : undefined,
        }}
      />
    </div>
  );
}
